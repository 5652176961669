import { withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function SlaySection() {
  const { title, description } = data.sections.slay

  return (
    <Container>
      <Video
        src="https://s3.amazonaws.com/skyweaver.net/video/hexbound-invasion/Heart_Zomboid_Clip.mp4"
        autoPlay
        muted
        loop
        playsInline
      />
      <Gradient />
      <Wrapper>
        <SlayImage
          src={withPrefix('/images/hexbound-invasion/slay-wreath.png')}
        />
        <H2>{title}</H2>
        <P>{description}</P>
      </Wrapper>
    </Container>
  )
}

const Gradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 1500px;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    90deg,
    rgba(12, 6, 30, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  @media (max-width: 600px) {
    background: linear-gradient(
      0deg,
      rgba(12, 6, 30, 1)  0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 1500px;
  height: 100%;
  z-index: 0;
  object-fit: cover;
`

const P = styled.p`
  color: white;
  font-size: 19px;
  line-height: 28px;
  color: #f2f2f2;
  font-family: var(--font);
  max-width: 420px;
  margin-top: 23px;
`

const H2 = styled.h2`
  font-family: var(--condensedFont);
  color: white;
  font-weight: 600;
  font-size: 54px;
  line-height: 65px;
  text-transform: uppercase;
`

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  padding-top: 130px;
  padding-bottom: 240px;
  position: relative;
  @media (max-width: 600px) {
    height: 950px;
    justify-content: flex-end;
    padding-bottom: 60px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  z-index: 2;
`

const SlayImage = styled.img`
  width: 116px;
  height: 116px;
  margin-bottom: 23px;
`
