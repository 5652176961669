import { Link, withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import CardRevealGrid from './CardRevealGrid'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function RevealSection() {
  const { title, items } = data.sections.calendar

  return (
    <Section>
      <Logo src={withPrefix('/images/hexbound-invasion/logo-small.png')} />
      <Title>{title}</Title>
      <P>
        Members of our <StyledLink href="/community/creators-program">Creators program</StyledLink> will reveal new cards until Hexbound
        Invasion gets released. Click to access their channels and stay tuned.
      </P>
      <CardRevealGrid items={items} />
    </Section>
  )
}

const Section = styled.section`
  border-top: 1px solid #705BAB;
  border-bottom: 1px solid #705BAB;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${withPrefix('/images/hexbound-invasion/reveal-calendar-bg.png')});
  background-size: cover;
  background-position: center;
  padding-top: 24px;
  padding-bottom: 48px;
`

const Logo = styled.img`
  width: 100%;
  max-width: 212px;
`

const StyledLink = styled(Link)`
  color: rgb(172, 143, 255);
  transition: 0.3s ease-out opacity;
  &:hover { opacity: 0.5 }
`

const P = styled.p`
  max-width: 590px;
  padding: 0 25px;
  font-family: var(--font);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 148%;
  text-align: center;
  color: #f2f2f2;
  margin-top: 20px;
  margin-bottom: 35px;
`

const Title = ({ children }) => {
  return (
    <TitleWrapper>
      <TitleDecoration
        src={withPrefix('/images/hexbound-invasion/decoration.png')}
      />
      <H2>{children}</H2>
      <TitleDecoration
        flip
        src={withPrefix('/images/hexbound-invasion/decoration.png')}
      />
    </TitleWrapper>
  )
}

const H2 = styled.h2`
  font-family: var(--condensedFont);
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0px 0px 26px #9f1c6a, 0px 0px 26px #9f1c6a;
  flex: 1;
  white-space: nowrap;

`

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -20px;
`

const TitleDecoration = styled.img`
  flex-shrink: 1;
  max-width: 182px;
  transform: ${({ flip = false }) => (flip ? 'scaleX(-1)' : 'scaleX(1)')};
  margin: 0 17px;
`
