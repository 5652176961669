import React from 'react'
import Subthemes from 'components/ClashOfInventors/Subthemes'
import styled from 'styled-components'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function SubthemesSection() {

  return (
    <Section>
      <Subthemes {...data.sections.subthemes} background={null} />
    </Section>
  )
}

const Section = styled.section`
  background: #1D0827;
  padding: 0 10px;
`