import { withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function SkypassSection() {
  const { title, description } = data.sections.skypass

  return (
    <Container>
      <Wrapper>
        <HexfectionImage
          src={withPrefix('/images/hexbound-invasion/skypass.png')}
        />
        <div>
          <H2>{title}</H2>
          <P>
            {description}
          </P>
        </div>
      </Wrapper>
    </Container>
  )
}

const P = styled.p`
  color: white;
  font-size: 19px;
  line-height: 28px;
  color: #f2f2f2;
  font-family: var(--font);
  max-width: 420px;
  margin-top: 23px;
  margin-bottom: 90px;
  white-space: pre-line;
`

const H2 = styled.h2`
  font-family: var(--condensedFont);
  color: white;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  text-transform: uppercase;
`

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 85%,
      rgba(29, 8, 39, 1) 100%
    ),
    url(${withPrefix('/images/hexbound-invasion/skypass-bg.png')});
  background-size: cover;
  background-position: center;
  padding-top: 130px;
  padding-bottom: 240px;
  @media (max-width: 800px) {
    padding-bottom: 0px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const HexfectionImage = styled.img`
  max-width: 502px;
  width: 100%;
  margin-bottom: 23px;
  @media (max-width: 800px) {
    max-width: 400px;
  }
`
