import { withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function ExploreSection() {
  const { text, title } = data.sections.explore

  return (
    <Section>
      <ContentImage
        src={withPrefix('/images/hexbound-invasion/explore-circle.png')}
      />
      <ContentContainer>
        <Logo src={withPrefix('/images/hexbound-invasion/logo-small.png')} />
        <Title>
          {title}
        </Title>
        <P>
          {text}
        </P>
        <CTA
          target="_blank"
          rel="noreferrer noopener"
          href="https://play.skyweaver.net/market/cards/buy?ownership=LOCKED&itemType=SW_SILVER_CARDS&set=Hexbound%20Invasion&sorting=PRICE_DESCENDING"
        >
          VIEW ALL CARDS
        </CTA>
      </ContentContainer>
    </Section>
  )
}

const Section = styled.section`
  position: relative;
  background: url(${withPrefix('/images/hexbound-invasion/explore-bg.png')});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 125vh;
  padding: 0 16px;
  z-index: 0;
`

const ContentImage = styled.img`
  width: 793px;
  height: 793px;
  position: absolute;
  top: calc(50% - (793px / 2));
  left: calc(50% - (793px / 2));
`

const ContentContainer = styled.div`
  width: 100%;
  max-width: 793px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
`

const Logo = styled.img`
  width: 100%;
  max-width: 388px;
  margin-top: -20px;
`

const Title = styled.h2`
  margin-top: -30px;
  font-family: var(--condensedFont);
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #f2f2f2;
  width: 100%;
  max-width: 396px;
  text-align: center;
  white-space: pre-line;
  text-transform: uppercase;
`

const P = styled.p`
  font-family: var(--font);
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  color: #f2f2f2;
  width: 100%;
  max-width: 396px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 25px;
`

const CTA = styled.a`
  background: #c62886;
  color: #ffffff;
  padding: 9px 31px;
  font-family: var(--font);
  border-radius: 4px;
  font-size: 18px;
  line-height: 25px;
  transition: 0.3s ease-out opacity;
  &:hover {
    opacity: 0.5;
  }
`
