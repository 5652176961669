import { withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function CardRevealGrid() {
  const { items } = data.sections.calendar

  return (
    <Wrapper>
      <GridList>
        {items.map((item, index) => (
          <GridItem key={index} index={index} {...item} />
        ))}
      </GridList>
    </Wrapper>
  )
}

const GridItem = ({
  image,
  date,
  revealed,
  text,
  username,
  platform,
  link,
  index
}) => {
  return (
    <GridListItem index={index} revealed={revealed}>
      <ItemLink
        revealed={revealed}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <ItemTitle revealed={revealed}>{date}</ItemTitle>
        <ItemImage src={image} />
        {!revealed && (
          <StreamerName>
            {!!platform ? (
              <img
                style={{ width: '18px', height: '18px', marginRight: 4 }}
                src={withPrefix(
                  platform.toUpperCase() == 'TWITCH'
                    ? '/images/clash-of-inventors/twitch-icon.png'
                    : '/images/clash-of-inventors/yt-icon.png'
                )}
              />
            ) : null}
            {username}
          </StreamerName>
        )}
        <ItemText>{text}</ItemText>
        {revealed && (
          <ItemCheck src={withPrefix(`/images/clash-of-inventors/check.svg`)} />
        )}
      </ItemLink>
    </GridListItem>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 838px;
  padding-left: 25px;
  padding-right: 25px;
`

const GridList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style-type: none;
`

const ItemLink = styled.a`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`

const GridListItem = styled.li`
  @media (min-width: 838px) {
    width: calc((100% / 5) - 12px);
    margin-left: ${({ index }) => index % 5 ? '12px' : '0px'};
    margin-bottom: 13px;
  }
  @media (max-width: 838px) {
    width: calc((100% / 4) - 12px);
    margin-left: ${({ index }) => index % 4 ? '12px' : '0px'};
    margin-bottom: 16px;
  }
  @media (max-width: 578px) {
    width: calc((100% / 3) - 12px);
    margin-left: ${({ index }) => index % 3 ? '12px' : '0px'};
    margin-bottom: 18px;
  }
  @media (max-width: 434px) {
    width: calc((100% / 2) - 12px);
    margin-left: ${({ index }) => index % 2 ? '12px' : '0px'};
    margin-bottom: 25px;
  }
  background: ${({ revealed }) => (revealed ? '#1C1038' : '#170D30')};
  box-shadow: 0px 0px 10px rgba(94, 62, 185, 0.5);
  border: 1px solid ${({ revealed }) => (revealed ? '#705BAB' : '#4D3C7B')};
  transition: 0.3s ease-out border-color;
  &:hover {
    border: 1px solid var(--lilac);
  }
  h3 { transition: 0.3s ease-out border-color; }
  &:hover h3 {
    border-bottom: 1px solid var(--lilac);
  }
`

const ItemTitle = styled.h3`
  font-family: var(--font);
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  width: 100%;
  padding: 6px;
  background: ${({ revealed }) => (revealed ? '#231445' : '#0C061E')};
  border-bottom: 1px solid
    ${({ revealed }) => (revealed ? '#705BAB' : '#4D3C7B')};
`

const ItemImage = styled.img`
  max-width: calc(100% - 24px);
  margin-top: 12px;
`

const StreamerName = styled.p`
  display: flex;
  align-items: flex-end;
  font-family: var(--font);
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  margin-top: 8px;
`

const ItemText = styled.p`
  font-family: var(--font);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ac8fff;
  padding: 8px;
  padding-bottom: 16px;
`

const ItemCheck = styled.img`
  position: absolute;
  top: 50px;
  right: 14px;
`
