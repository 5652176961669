import { withPrefix } from 'gatsby'
import React, { useState } from 'react'
import styled from 'styled-components'

export default function Player({ src }) {
  const [canPlay, setCanPlay] = useState(false)

  return (
    <OuterWrapper>
      <InnerWrapper>
        {canPlay ? (
          <IFrame
            title="Skyweaver"
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <Thumbnail
            onClick={e => {
              e.preventDefault()
              setCanPlay(true)
            }}
          />
        )}
      </InnerWrapper>
    </OuterWrapper>
  )
}

const Thumbnail = ({ onClick }) => {
  return (
    <ThumbnailWrapper onClick={onClick}>
      <ThumbnailImg src="/images/hexbound-invasion/hero-video-thumbnail.png" />
      <PlayButton
        className="play-btn"
        src={withPrefix('/images/0-intro/play.svg')}
      />
    </ThumbnailWrapper>
  )
}

const InnerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
  background: #000000;
  border: 1px solid #AC8FFF;
`

const OuterWrapper = styled.div`
  width: 100%;
  max-width: 650px;
  padding: 0 25px;
  margin: 0 auto;
  position: relative;
`

const ThumbnailWrapper = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  &:hover .play-btn {
    opacity: 0.5;
  }
`

const ThumbnailImg = styled.img`
  width: 100%;
`

const PlayButton = styled.img`
  width: 5rem;
  height: 5rem;
  position: absolute;
  top: calc(50% - 2.5rem);
  left: calc(50% - 2.5rem);
`

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
