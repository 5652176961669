import { withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function JoinSection() {
  const { title, text } = data.sections.join
  
  return (
    <Section>
      <Title>{title}</Title>
      <P>
        {text}
      </P>
      <CTA />
    </Section>
  )
}

const CTA = () => {
  return (
    <CTALink
      href="https://play.skyweaver.net"
      target="_blank"
      rel="noopener noreferrer"
    >
      <CTAImg src={withPrefix('/images/clash-of-inventors/play-now.png')} />
    </CTALink>
  )
}

const CTALink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  width: 325px;
  height: 65px;
  margin-top: 65px;
  transition: 0.3s ease-out opacity;
  &:hover {
    opacity: 0.5;
  }
`

const CTAImg = styled.img`
  max-width: 616px;
  pointer-events: none;
  @media (max-width: 500px) {
    max-width: 450px;
  }
`

const P = styled.p`
  color: #ffffff;
  font-family: var(--font);
  font-weight: 500;
  font-size: 17px;
  line-height: 140%;
  text-align: center;
  width: 100%;
  max-width: 376px;
  margin-top: 32px;
`

const Title = styled.h2`
  color: #ffffff;
  font-family: var(--condensedFont);
  font-weight: 600;
  font-size: 46px;
  line-height: 55px;
  text-align: center;
  text-transform: uppercase;
  margin-top: -145px;
`

const Section = styled.section`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 45%,
      rgba(0, 0, 0, 1) 85%
    ),
    url(${withPrefix('/images/hexbound-invasion/join-bg.jpg')});
  background-size: cover;
  background-position: center;
  height: 935px;
  @media (max-width: 750px) {
    height: 650px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
`
