import { withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Player from './Player'

import { data } from 'data/expansion-pack/hexbound-invasion'

export default function HeroSection() {
  const { video, copy } = data.hero
  
  return (
    <Section>
      <Background>
        <Gradient />
      </Background>
      <Wrapper showVideo={video.show}>
        <LogoImage src={withPrefix('/images/hexbound-invasion/logo.png')} />
        <div>
          {video.show && <Player src={video.source} />}
          <P>{copy}</P>
          {/* <CTA>LEARN MORE</CTA> */}
        </div>
      </Wrapper>
    </Section>
  )
}

const P = styled.p`
  max-width: 650px;
  padding: 0 10px;
  color: white;
  font-family: var(--font);
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 152px;
`

const CTA = styled.button`
  background: #6e1897;
  border-radius: 4px;
  color: white;
  width: 167px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font);
  font-size: 18px;
  line-height: 25px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 90px;
  transition: 0.3s ease-out opacity;
  &:hover {
    opacity: 0.5;
  }
`

const LogoImage = styled.img`
  width: 100%;
  max-width: 689px;
  margin-top: 20px;
`

const Section = styled.section`
  background: #1d0827;
`

const Wrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  height: ${({ showVideo }) =>
    showVideo
      ? 'max(min(275vw, 1795px), 1043px)'
      : 'max(min(205vw, 1395px), 100vh)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url(${withPrefix('/images/hexbound-invasion/hero.png')});
  pointer-events: none;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: max(min(175vw, 1233px), 607px);
`

const Gradient = styled.div`
  width: 100%;
  height: 200px;
  background: linear-gradient(180deg, rgba(29, 8, 39, 0) 0%, #1d0827 85%);
`
