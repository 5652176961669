import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Header from '../Header'
import Footer from 'components/Footer'
import HeroSection from './HeroSection'
import SlaySection from './SlaySection'
import SkypassSection from './SkypassSection'
import HexfectionSection from './HexfectionSection'
import SubthemesSection from './SubthemesSection'
import ExploreSection from './ExploreSection'
import JoinSection from './JoinSection'
import RevealSection from './RevealSection'
import Seo from 'components/SeoHead'

import 'css/main.css'
import 'css/fonts.css'

export default function HexboundInvasion() {
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
    <Seo img="https://skyweaver.net/images/hexbound-invasion/social-share.png" />
    <Main>
      <Header enforceBackground />
      <HeroSection />
      <SlaySection />
      <SkypassSection />
      <HexfectionSection />
      <SubthemesSection />
      <RevealSection />
      <ExploreSection />
      <JoinSection />
      <Footer windowWidth={windowWidth} />
    </Main>
    </>
  )
}

const Main = styled.main`
  width: 100vw;
  overflow: hidden;
  position: relative;
`